import * as React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ButtonUp from "../utilities/ButtomUp";
import { StaticImage } from "gatsby-plugin-image";
import { AiFillFacebook } from "@react-icons/all-files/ai/AiFillFacebook";

import { FaCircle } from "@react-icons/all-files/fa/FaCircle";
import { GiGreenhouse } from "@react-icons/all-files/gi/GiGreenhouse";
import { RiMailSendLine } from "@react-icons/all-files/ri/RiMailSendLine";
import { GiStrong } from "@react-icons/all-files/gi/GiStrong";

import Seo from "../components/Seo";

const Img01 = "../images/about1.jpg";
const Img02 = "../images/about2.jpg";

const About = () => {
  return (
    <div className="relative overflow-hidden bg-stone-50 ">
      <Seo title="About" pathname="/about/" />
      <div id="top" />
      <Navbar className="" />
      <div className="flex flex-col justify-center items-center -translate-y-6 md:-translate-y-2 px-4">
        <div className="w-full 2xl:w-[1440px] h-full flex justify-center mb-6 md:mb-10 font-cardo text-3xl md:text-4xl font-bold">
          Our Story
        </div>
        <div className="w-full 2xl:w-[1440px] h-full flex flex-col md:flex-row justify-center items-start">
          <div className="w-full md:w-[50%] flex justify-center items-center">
            <div className="w-[100%] md:w-[80%] rounded-md shadow-lg">
              <StaticImage
                src={Img01}
                alt="about"
                layout="constrained"
                placeholder="blurred"
                className={`object-contain w-full rounded-md shadow-lg`}
              />
            </div>
          </div>

          <div className="w-full md:w-[40%] flex flex-col justify-start items-start">
            <div className="mt-6 md:mt-0 flex flex-col font-cardo font-medium text-xl md:text-2xl text-left italic">
              “Knowledge is the best weapon to fight against the environmental
              crisis”
              <div className="flex items-center justify-end w-full mt-1">
                <div className="w-[50px] h-0.5 bg-stone-300 mr-5"></div>
                <div className="font-jarkatar text-base not-italic">
                  The Econews
                </div>
              </div>
            </div>

            <div className="flex items-end font-jarkatar font-medium text-md lg:text-lg text-justify mt-6 md:mt-12">
              The EcoNews Laos is a young news platform in Laos on the topic of
              environment. We believe that knowledge is the best weapon to fight
              against the environmental crisis. Our aim is to raise awareness
              and provide knowledge among the citizens about environmental
              issues and solutions in Laos and from around the globe by young
              and dynamic journalists.
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-center items-start w-full 2xl:w-[1440px] md:py-12 border-stone-200 border-b-2">
          <div className="w-full md:w-[70%] md:pr-8 h-full flex flex-col mt-8 md:mt-0">
            <div className="font-cardo font-bold text-2xl flex w-full ">
              <FaCircle className="text-xs mt-2.5 mx-3 text-stone-800" />
              What we are working on
              <FaCircle className="text-xs mt-2.5 mx-3 text-stone-800" />
            </div>

            <div className="w-full md:pb-12 mt-6 md:mt-10 flex flex-col md:flex-row">
              <div className="px-4 w-full md:w-1/3 mb-4 md:mb-0 h-full flex flex-col items-center justify-start">
                <div className="relative bg-green-110 w-28 h-28 flex justify-center items-center mt-4 rounded-full">
                  <GiGreenhouse className="text-white text-6xl" />
                </div>
                <div className="font-jarkatar font-semibold text-lg mt-3 w-full text-center">
                  We raise awareness on conservation in the society
                </div>
                <div className="font-jarkatar font-normal text-base mt-3 text-justify">
                  Education is the most powerful weapon to change the world. We
                  hope that providing a platform for Lao people to read, see and
                  hear about the environmental issues as well as the positive
                  changes happing in the world will be another force to make a
                  change
                </div>
              </div>

              <div className="px-4 w-full md:w-1/3 mb-4 md:mb-0 h-full flex flex-col items-center justify-start">
                <div className="relative bg-green-110 w-28 h-28 flex justify-center items-center mt-4 rounded-full">
                  <RiMailSendLine className="text-white text-5xl" />
                </div>
                <div className="font-jarkatar font-semibold text-lg mt-3 w-full text-center">
                  We bring the topic of the environment closer to you
                </div>
                <div className="font-jarkatar font-normal text-base mt-3 text-justify">
                  Our aim is to make the topic about the environment a subject
                  close to your everyday life. We are bringing the news from
                  around the world to Lao people’s eyes and at the same time
                  bringing the stories happening in Laos to an international
                  stage. Our followers will have a more easy-to-digest and
                  quicker way to access the news.
                </div>
              </div>

              <div className="px-4 w-full md:w-1/3 mb-4 md:mb-0 h-full flex flex-col items-center justify-start">
                <div className="relative bg-green-110 w-28 h-28 flex justify-center items-center mt-4 rounded-full">
                  <GiStrong className="text-white text-6xl" />
                </div>
                <div className="font-jarkatar font-semibold text-lg mt-3 w-full text-center">
                  Power by Lao youth
                </div>
                <div className="font-jarkatar font-normal text-base mt-3 text-justify">
                  We are the young journalists, environmentalists and
                  change-makers who have the same mission of creating a healthy
                  and sustainable environment. It is not just for the future but
                  it has to be happening now.
                </div>
              </div>
            </div>
          </div>

          <div className="w-[90%] md:w-[25%] h-full flex flex-col justify-start items-start mb-10">
            <a
              href="https://www.facebook.com/econewslao/"
              className="transition-all duration-200 hover:text-blue-600 rounded-md font-jarkatar text-lg font-medium flex items-center justify-start mt-8 md:mt-0 py-2 px-4 border-[2px] border-stone-800"
            >
              Find us on
              <AiFillFacebook className="cursor-pointer text-4xl ml-2" />
            </a>

            <div className="w-full pt-6 shadow-lg">
              <StaticImage
                src={Img02}
                alt="about"
                layout="constrained"
                placeholder="blurred"
                className={`object-contain w-full h-full rounded-md shadow-lg`}
              />
            </div>
          </div>
        </div>

        <Footer />
      </div>
      <ButtonUp link={"about/#top"} />
    </div>
  );
};

export default About;
